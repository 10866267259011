import React, {useState, useEffect} from "react";

export default function GiletPrimoRVBack({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -150));
  }, lightContrast);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g>
        <path
          fill={lightContrast}
          onClick={() => handleClick("base")}
          onMouseEnter={() => setLightContrastActive(true)}
          onMouseLeave={() => setLightContrastActive(false)}
          style={
            lightContrastActive && !preview
              ? {fill: adjust(lightContrast, 55)}
              : {fill: lightContrast}
          }
          d="M401.183,222.299l-0.037-0.25l-0.222-0.111c-0.074-0.037-7.577-4.205-13.451-41.046
     c-4.69-29.437,3.446-65.131,7.818-84.304c1.207-5.292,2.077-9.117,2.261-11.05c0.809-8.557-0.911-9.968-1.304-10.185
     c-0.282-0.12-28.433-12.424-49.997-19.543c-21.48-7.095-62.161-24.136-63.892-24.857l-0.314,0.06l-0.028-0.074
     c-0.148,0.051-15.148,5.333-34.326,6.485c-25.33,0.925-43.35-6.078-44.307-6.462l-0.134-0.125l-0.278,0.116
     c-0.421,0.18-42.314,17.734-63.887,24.857c-21.559,7.119-49.714,19.422-50.034,19.566c-0.351,0.194-2.072,1.605-1.262,10.162
     c0.185,1.933,1.054,5.759,2.262,11.05c4.371,19.173,12.507,54.867,7.812,84.304c-5.869,36.842-13.372,41.009-13.441,41.042
     l-0.231,0.111l-0.033,0.254c-0.009,0.079-0.994,8.132-0.582,14.371c1.022,15.653,1.605,31.616,2.164,47.05
     c0.402,10.995,0.815,22.36,1.383,33.521c1.439,28.114,3.104,53.642,5.083,78.041c1.096,13.465,2.303,26.735,3.589,39.446
     c0.726,7.142,1.397,13.423,2.049,19.196c0.079,0.69,0.121,1.665,0.167,2.789c0.162,3.927,0.384,9.306,2.511,10.99
     c0.796,0.629,1.809,0.856,2.646,1.045c0.352,0.06,35.921,6.124,87.852,9.135c16.522,0.958,35.908,1.665,57.148,1.665
     c40.357,0,87.38-2.553,134.004-10.8c0.731-0.13,1.804-0.379,2.646-1.045c2.128-1.684,2.349-7.063,2.512-10.99
     c0.046-1.124,0.087-2.099,0.161-2.789c0.657-5.773,1.328-12.054,2.05-19.196c1.291-12.711,2.498-25.981,3.589-39.446
     c1.984-24.399,3.645-49.927,5.083-78.041c0.573-11.161,0.985-22.526,1.383-33.521c0.565-15.435,1.143-31.398,2.17-47.05
     C402.177,230.431,401.192,222.378,401.183,222.299z"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.5033"
          strokeMiterlimit="10"
          strokeDasharray="1.4945,1.4931"
          d="
     M237.114,43.38c32.561,1.645,46.765-8.282,46.765-8.282s0.813-0.469,1.812-1.098"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.5033"
          strokeMiterlimit="10"
          strokeDasharray="1.4945,1.4931"
          d="
     M85.579,231.95c3.287-4.097,8.185-11.168,12.393-21.17c3.309-7.865,4.482-16.709,5.455-25.121
     c1.283-11.089,2.093-22.265,1.653-33.432c-0.437-11.078-2.498-21.949-4.591-32.81c-0.53-2.754-1.062-5.508-1.569-8.267
     c-1.471-8.012-2.963-16.02-4.477-24.025c0,0-1.414-6.149-0.418-12.101"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.5033"
          strokeMiterlimit="10"
          strokeDasharray="1.4945,1.4931"
          d="
     M237.114,43.38c-24.972-0.638-35.673-8.282-35.673-8.282s-0.565-0.39-1.341-0.927"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.5033"
          strokeMiterlimit="10"
          strokeDasharray="1.4945,1.4931"
          d="
     M399.742,231.95c-3.287-4.097-8.185-11.168-12.394-21.17c-3.309-7.865-4.483-16.709-5.456-25.121
     c-1.282-11.089-2.093-22.265-1.652-33.432c0.437-11.078,2.498-21.949,4.591-32.81c0.53-2.754,1.062-5.508,1.569-8.267
     c1.472-8.012,2.963-16.02,4.478-24.025c0,0,1.414-6.149,0.418-12.101"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.5033"
          strokeMiterlimit="10"
          strokeDasharray="1.4945,1.4931"
          d="
     M98.504,460.674c0,0,150.689,23.41,288.354-0.938"
        />
        <path
          fill="#010101"
          d="M401.174,222.34l-0.037-0.25l-0.222-0.111c-0.074-0.037-7.577-4.205-13.451-41.046
     c-4.69-29.437,3.446-65.131,7.818-84.304c1.207-5.292,2.077-9.117,2.261-11.05c0.809-8.557-0.911-9.968-1.304-10.185
     c-0.282-0.12-28.433-12.424-49.997-19.543c-21.48-7.095-62.161-24.136-63.892-24.857l-0.314,0.06l-0.028-0.074
     c-0.148,0.051-15.148,5.333-34.326,6.485c-25.33,0.925-43.35-6.078-44.307-6.462l-0.134-0.125l-0.278,0.116
     c-0.421,0.18-42.314,17.734-63.887,24.857c-21.559,7.119-49.714,19.422-50.034,19.566c-0.351,0.194-2.072,1.605-1.262,10.162
     c0.185,1.933,1.054,5.759,2.262,11.05c4.371,19.173,12.507,54.867,7.812,84.304c-5.869,36.842-13.372,41.009-13.441,41.042
     l-0.231,0.111l-0.033,0.254c-0.009,0.079-0.994,8.132-0.582,14.371c1.022,15.653,1.605,31.616,2.164,47.05
     c0.402,10.995,0.815,22.36,1.383,33.521c1.439,28.114,3.104,53.642,5.083,78.041c1.096,13.465,2.303,26.735,3.589,39.446
     c0.726,7.142,1.397,13.423,2.049,19.196c0.079,0.69,0.121,1.665,0.167,2.789c0.162,3.927,0.384,9.306,2.511,10.99
     c0.796,0.629,1.809,0.856,2.646,1.045c0.352,0.06,35.921,6.124,87.852,9.135c16.522,0.958,35.908,1.665,57.148,1.665
     c40.357,0,87.38-2.553,134.004-10.8c0.731-0.13,1.804-0.379,2.646-1.045c2.128-1.684,2.349-7.063,2.512-10.99
     c0.046-1.124,0.087-2.1,0.161-2.789c0.657-5.773,1.328-12.054,2.05-19.196c1.291-12.711,2.498-25.981,3.589-39.446
     c1.984-24.399,3.645-49.927,5.083-78.041c0.573-11.161,0.985-22.526,1.383-33.521c0.565-15.435,1.143-31.398,2.17-47.05
     C402.168,230.471,401.184,222.418,401.174,222.34 M282.175,31.433v0.005l-0.005-0.005H282.175z M203.162,31.914
     c1.804,0.703,17.577,6.559,39.927,6.559c1.513,0,3.062-0.028,4.635-0.088c18.247-1.092,32.734-5.897,34.423-6.476
     c0.657,0.273,2.886,1.207,6.203,2.576c-1.175,1.008-3.691,2.539-4.736,3.145c-0.139,0.097-14.487,9.811-46.477,8.201l-0.023,0.458
     l0.014-0.463c-24.51-0.624-35.311-8.118-35.422-8.196c-0.037-0.028-2.942-2.031-4.607-3.201
     C200.41,33.065,202.602,32.145,203.162,31.914 M85.042,222.673c1.378-0.93,8.242-7.179,13.728-41.592
     c4.723-29.617-3.442-65.423-7.826-84.66c-1.198-5.255-2.068-9.057-2.244-10.93c-0.717-7.581,0.639-9.117,0.805-9.269
     c0.375-0.167,2.794-1.216,6.546-2.803c-1.559,6.388,0.037,13.497,0.106,13.793c1.457,7.697,2.965,15.778,4.478,24.025
     c0.49,2.66,1.008,5.361,1.513,7.974l0.056,0.296c1.943,10.074,4.14,21.49,4.584,32.739c0.384,9.718-0.139,20.315-1.651,33.359
     c-1.018,8.802-2.197,17.332-5.421,24.996c-5.81,13.812-13.016,22.073-15.301,24.483C84.255,229.828,84.903,223.884,85.042,222.673
      M384.236,467.018c-0.68,0.537-1.6,0.749-2.234,0.861c-71.061,12.567-143.052,11.896-190.926,9.126
     c-51.879-3.007-87.407-9.066-87.735-9.121c-0.921-0.208-1.67-0.402-2.253-0.865c-1.554-1.23-1.929-5.407-2.096-8.802
     c4.88,0.726,41.334,5.985,92.043,8.581c16.374,0.837,35.366,1.448,56.009,1.448c42.342,0,91.608-2.563,139.329-10.954
     C386.22,460.806,385.933,465.672,384.236,467.018 M400.832,236.651c-1.027,15.666-1.605,31.639-2.17,47.078
     c-0.397,10.99-0.809,22.35-1.383,33.502c-1.434,28.104-3.099,53.623-5.079,78.018c-1.096,13.461-2.299,26.722-3.589,39.428
     c-0.722,7.142-1.392,13.414-2.044,19.182c-0.074,0.643-0.116,1.503-0.157,2.484c-70.955,12.503-145.355,12.086-195.32,9.533
     c-51.376-2.632-88.097-7.997-92.14-8.603c-0.009-0.189-0.019-0.375-0.023-0.555c-0.051-1.148-0.093-2.133-0.172-2.859
     c-0.656-5.768-1.322-12.04-2.049-19.182c-1.286-12.706-2.493-25.967-3.585-39.428c-1.984-24.395-3.645-49.913-5.083-78.018
     c-0.569-11.152-0.985-22.512-1.383-33.502c-0.56-15.439-1.142-31.412-2.169-47.078c-0.005-0.097-0.01-0.199-0.018-0.296
     c1.221-1.212,9.547-9.824,16.097-25.394c3.275-7.775,4.464-16.379,5.49-25.25c1.517-13.09,2.04-23.738,1.656-33.502
     c-0.444-11.319-2.651-22.771-4.598-32.878l-0.056-0.296c-0.504-2.609-1.026-5.31-1.512-7.97
     c-1.518-8.247-3.021-16.328-4.483-24.043c-0.019-0.079-1.735-7.729,0.065-14.057c9.903-4.168,27.596-11.402,42.239-16.235
     c16.167-5.338,43.702-16.518,56.713-21.879c1.513,1.069,5.06,3.515,5.092,3.534c0.44,0.315,11.097,7.734,35.922,8.368
     c2.465,0.125,4.824,0.185,7.082,0.185c27.397,0,39.834-8.455,39.936-8.529c0.166-0.097,3.871-2.243,5.185-3.529
     c13.03,5.375,40.353,16.467,56.657,21.851c14.64,4.834,32.336,12.068,42.24,16.235c1.799,6.328,0.083,13.978,0.06,14.075
     c-1.457,7.697-2.964,15.777-4.477,24.025c-0.49,2.66-1.008,5.361-1.513,7.97l-0.055,0.296c-1.947,10.107-4.154,21.559-4.598,32.878
     c-0.389,9.764,0.138,20.412,1.651,33.502c1.027,8.872,2.22,17.475,5.49,25.25c6.55,15.569,14.88,24.182,16.097,25.394
     C400.846,236.452,400.841,236.554,400.832,236.651 M400.906,235.083c-2.285-2.41-9.487-10.671-15.301-24.483
     c-3.224-7.664-4.404-16.194-5.421-24.996c-1.508-13.044-2.031-23.641-1.651-33.359c0.444-11.249,2.646-22.665,4.584-32.739
     l0.056-0.296c0.504-2.613,1.027-5.315,1.517-7.974c1.513-8.247,3.021-16.328,4.473-24.006c0.07-0.315,1.666-7.424,0.107-13.812
     c3.751,1.587,6.17,2.637,6.55,2.803c0.162,0.153,1.522,1.688,0.805,9.269c-0.18,1.873-1.046,5.675-2.243,10.93
     c-4.385,19.237-12.549,55.043-7.826,84.66c5.486,34.414,12.345,40.663,13.728,41.592
     C400.42,223.884,401.063,229.824,400.906,235.083"
        />
      </g>
    </svg>
  );
}
